<template>
    <NavBar :showUserMenu="showUserMenu" style="width: 100%;"/>
    <router-view id="page-content" />
    <AppFooter />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NavBar from './components/NavBar.vue';
import AppFooter from './components/AppFooter.vue';

export default defineComponent({
    name: 'App',
    components: {
        NavBar,
        AppFooter,
    },
    computed: {
        showUserMenu() {
            // True if the route name is one of ['Dashboard']
            return ['dashboard'].includes(this.$route.name?.toString() ?? '')
        }
    },
});
</script>

<style>
html,
body,
#app {
    height: 100%;
    margin: 0;
}

#app {
    display: flex;
    flex-direction: column;
}

#page-content {
    flex-grow: 1;
}
</style>