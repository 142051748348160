import { createRouter, createWebHistory } from 'vue-router'
import { appAuth } from "@/firebase/config";

const tabTitleBase = 'CareeredUp';

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import("../views/HomeView.vue"),
    },
    // {
    //     path: '/about',
    //     name: 'about',
    //     component: AboutView
    // },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import("../views/DashboardView.vue"),
        meta: {
            requiresAuth: true,
            title: tabTitleBase + ' | Dashboard'
        },
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import("../views/TermsView.vue"),
    },
    {
        path: '/privacy',
        name: 'privacy-policy',
        component: () => import("../views/PrivacyPolicyView.vue"),
    },
    {
        path: '/processors',
        name: 'sub-processors',
        component: () => import("../views/SubProcessorsView.vue"),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import("../views/LoginView.vue"),
    }
]

// TODO: Move to HTML mode to improve SEO
// But first, check how to handle 404 errors in HTML mode and Firebase hosting
// https://next.router.vuejs.org/guide/essentials/history-mode.html#hash-mode
const router = createRouter({
    history: createWebHistory(), //createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // console.log("scrollBehavior");
        // console.log(to);
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }
    }
})

router.beforeEach((to, from, next) => {
    // Check if the current route or any of its parents have a title defined
    const pageTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Assuming pageTitle.meta.title is where the uncertainty of type comes from
    document.title = pageTitle ? pageTitle.meta.title as string : tabTitleBase;

    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    if (requiresAuth) {
        appAuth.onAuthStateChanged((_user) => {
            if (!_user) {
                next({ name: 'login' });
                // const provider = new GoogleAuthProvider();
                // signInWithPopup(appAuth, provider)
            } else {
                next();
            }
        });
    } else {
        next();
    }
});


export default router
