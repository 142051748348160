<!-- eslint-disable max-len -->
<template>
    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 30">
        <rect x="0" y="0" width="100" height="30" rx="6" ry="6" fill="#0a66c2" />
        <text x="50" y="21" text-anchor="middle" font-size="17" fill="#FFFFFF" font-family="Roboto, Arial, sans-serif"
            font-weight="bold">CareeredUp</text>
    </svg> -->
    <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100" version="1.1">
        <g fill="#0a66c2">
            <path
                d="M 36.90625 94.875 C 36.386719 94.234375 36.386719 94.132812 36.4375 79.058594 L 36.511719 63.910156 L 38.738281 61.707031 C 42.03125 58.441406 44.703125 55.347656 48.441406 50.394531 C 50.097656 48.21875 50.46875 48.390625 53.710938 52.722656 C 55.941406 55.667969 58.042969 58.09375 61.164062 61.289062 L 63.863281 64.035156 L 63.886719 70.074219 C 64.011719 90.890625 63.984375 94.257812 63.636719 94.902344 L 63.292969 95.542969 L 37.402344 95.542969 Z M 36.90625 94.875 " />
            <path
                d="M 8.664062 66.164062 C 7.921875 65.890625 7.425781 65.246094 7.425781 64.578125 C 7.425781 63.835938 7.722656 63.539062 11.261719 60.570312 C 14.875 57.550781 21.289062 50.867188 25.050781 46.164062 C 30.496094 39.382812 34.875 32.773438 40.59375 22.746094 C 41.015625 22.003906 43.070312 17.996094 45.171875 13.8125 C 47.5 9.183594 49.207031 6.089844 49.53125 5.941406 C 50.199219 5.570312 50.867188 5.816406 51.4375 6.632812 C 51.707031 7.003906 53.441406 10.320312 55.320312 13.984375 C 57.203125 17.671875 59.160156 21.4375 59.679688 22.351562 C 60.222656 23.292969 60.644531 24.085938 60.644531 24.132812 C 60.644531 24.480469 67.078125 34.949219 68.863281 37.5 C 69.257812 38.042969 70.023438 39.160156 70.59375 39.976562 C 75.742188 47.449219 83.242188 55.691406 89.949219 61.261719 C 92.574219 63.441406 93.019531 64.058594 92.722656 65.171875 C 92.476562 66.238281 91.757812 66.335938 84.28125 66.335938 C 76.1875 66.335938 76.1875 66.335938 72.894531 64.132812 C 65.125 58.910156 56.757812 49.875 51.386719 40.914062 C 50.867188 40.050781 50.320312 39.355469 50.171875 39.355469 C 50 39.355469 49.554688 39.875 49.160156 40.542969 C 44.800781 47.574219 41.164062 52.105469 35.765625 57.203125 C 32.128906 60.667969 30.296875 62.152344 26.980469 64.355469 C 23.910156 66.386719 24.109375 66.335938 16.136719 66.3125 C 12.226562 66.3125 8.863281 66.238281 8.664062 66.164062 Z M 8.664062 66.164062 " />
        </g>
    </svg>
</template>
  
<script>
export default {
    name: 'SvgLogo',
    mounted() {
        const link = document.createElement('link')
        link.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap'
        link.rel = 'stylesheet'
        document.head.appendChild(link)
    }
}
</script>

<!-- Original SVG:
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 30">
    <defs>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
        </style>
    </defs>
    <rect x="0" y="0" width="100" height="30" rx="6" ry="6" fill="#006699" />
    <text x="50" y="25" text-anchor="middle" font-size="27" fill="#FFFFFF" font-family=" Roboto, Arial, sans-serif"
        font-weight="bold">Profiler</text>
</svg> 
-->