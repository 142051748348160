<template>
    <div class="popup" @click="openWindow">
        <slot name="icon"></slot>
        <div class="window" v-show="showWindow" ref="window" :style="windowStyle">
            <slot name="menu"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "PopupWindow",
    props: {
        windowTop: {
            type: String,
            default: null
        },
        windowRight: {
            type: String,
            default: null
        },
        windowBottom: {
            type: String,
            default: null
        },
        windowLeft: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            showWindow: false,
        };
    },
    computed: {
        windowStyle() {
            let style = {};
            if (this.windowTop !== null) style.top = this.windowTop;
            if (this.windowRight !== null) style.right = this.windowRight;
            if (this.windowBottom !== null) style.bottom = this.windowBottom;
            if (this.windowLeft !== null) style.left = this.windowLeft;
            return style;
        }
    },
    methods: {
        openWindow() {
            if (!this.showWindow) {
                this.showWindow = true;
                setTimeout(() => {
                    document.addEventListener('click', this.closeWindow);
                });
            }
        },
        closeWindow(event) {
            if (this.showWindow && !this.$refs['window'].contains(event.target)) {
                this.showWindow = false;
                document.removeEventListener('click', this.closeWindow);
            }
        }
    },
    beforeUnmount() {
        document.removeEventListener('click', this.closeWindow);
    }
};
</script>

<style scoped>
.popup {
    position: relative;
}

.popup .window {
    position: absolute;
    text-align: left;
    padding: 10px 15px;
    background-color: var(--background-color-secondary);
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 2;
    max-width: 250px;
    width: max-content;
}
</style>
