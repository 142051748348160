import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppFooter = _resolveComponent("AppFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavBar, {
      showUserMenu: _ctx.showUserMenu,
      style: {"width":"100%"}
    }, null, 8, ["showUserMenu"]),
    _createVNode(_component_router_view, { id: "page-content" }),
    _createVNode(_component_AppFooter)
  ], 64))
}