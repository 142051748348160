<template>
    <nav>
        <section id="navigation">
            <router-link class="logo-container" :to="{ name: 'home' }">
                <SvgLogo id="logo" />
                <p>CareeredUp</p>
            </router-link>

            <div class="nav-links">
                <!-- <router-link class="nav-link" :to="{ name: 'about' }">About</router-link> -->
                <router-link class="nav-link" :to="{ name: 'home', hash: '#prices' }">Pricing</router-link>
                <router-link class="nav-link" :to="{ name: 'home', hash: '#faq' }">FAQs</router-link>
            </div>
            <div class="nav-user">
                <div v-if="user && showUserMenu">
                    <PopupWindow :windowTop="'60px'" :windowRight="'0'" style="cursor: pointer;">
                        <template #icon>
                            <UserProfileIcon />
                        </template>
                        <template #menu>
                            <button id="logout-button" @click="logout">Logout</button>
                        </template>
                    </PopupWindow>
                </div>
                <div v-else>
                    <router-link :to="{ name: 'dashboard' }">
                        <button class="pill get-started">Go To Studio</button>
                    </router-link>
                </div>
            </div>
        </section>
    </nav>
</template>

<script>
import { appAuth } from "@/firebase/config";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import PopupWindow from './PopupWindow.vue';
import SvgLogo from "@/components/Logo.vue";
import UserProfileIcon from "@/components/UserProfileIcon.vue";

export default {
    name: "NavBar",
    components: {
        UserProfileIcon,
        PopupWindow,
        SvgLogo
    },
    props: {
        showUserMenu: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            user: appAuth.currentUser
        };
    },
    created() {
        appAuth.onAuthStateChanged(_user => {
            console.log("User state change. Current user is:", _user);
            this.user = _user;
        });
    },
    methods: {
        // async login() {
        //     if (this.user) {
        //         this.$router.push('/dashboard');
        //     } else {
        //         const provider = new GoogleAuthProvider();
        //         signInWithPopup(appAuth, provider)
        //             .then(result => {
        //                 // This gives you a Google Access Token. You can use it to access the Google API.
        //                 const credential = GoogleAuthProvider.credentialFromResult(result);
        //                 const token = credential.accessToken;
        //                 // The signed-in user info.
        //                 const user = result.user;
        //                 // IdP data available using getAdditionalUserInfo(result)
        //                 console.log("Logged in!" + user.displayName + " " + user.email);
        //                 this.$router.push('/dashboard');
        //             })
        //             .catch(error => {
        //                 // Handle Errors here.
        //                 const errorCode = error.code;
        //                 const errorMessage = error.message;
        //                 // The email of the user's account used.
        //                 const email = error.customData.email;
        //                 // The AuthCredential type that was used.
        //                 const credential = GoogleAuthProvider.credentialFromError(error);
        //             });
        //     }
        // },
        logout() {
            appAuth.signOut().then(() => {
                // Navigate to home route
                this.$router.push('/')
                // TODO: Add proper User Disconnected event handling
            })
        },
    }
};
</script>

<style scoped>
.logo-container {
    display: flex;
    align-items: center;
    margin-right: auto;
    text-decoration: none !important;
    gap: 10px;

    #logo {
        width: 50px;
        height: 50px;
    }

    p {
        padding-top: 10px;
        font-size: 1.6rem;
        font-weight: 700;
        color: var(--accent-color);

        @media only screen and (max-width: 992px) {
            display: none;
        }
    }
}

nav {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 16px 0;
    background: var(--background-color-secondary);
}

#navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1500px;
    padding: 0px 20px;
    width: 100%;
    margin: 0 auto;
}

.nav-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 70px;
    gap: 70px;

    @media (max-width: 600px) {
        display: none;
    }
}

.nav-link {
    color: var(--text-color-secondary);
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    transition-duration: 150ms;
    /* padding: 20px; */
}

.nav-link:hover {
    color: var(--text-color);
}

.get-started {
    margin-left: 10px;
    height: 48px;
}

#logout-button {
    display: block;
    width: 100%;
    padding: 5px;
    margin-bottom: 5px;
    text-align: left;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--text-color);
}
</style>