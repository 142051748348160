import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/global.css'

/* import the fontawesome icons */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faTrash,
    faPen,
    faPlus,
    faCheck,
    faInfoCircle,
    faInfo,
    faTriangleExclamation,
    faClipboardQuestion,
    faMagnifyingGlass,
    faUserGroup,
} from '@fortawesome/free-solid-svg-icons'
import { faEnvelope, faComment, faAddressCard } from '@fortawesome/free-regular-svg-icons'
import { dom } from '@fortawesome/fontawesome-svg-core'
dom.watch()

/* add the fontawesome icons to the library */
library.add(
    faTrash,
    faPen,
    faPlus,
    faCheck,
    faInfoCircle,
    faInfo,
    faTriangleExclamation,
    faClipboardQuestion,
    faEnvelope,
    faComment,
    faMagnifyingGlass,
    faUserGroup,
    faAddressCard
)

createApp(App).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
