<template>
    <div class="profile-icon">
        <img class="profile-icon" :src="userPictureUrl" alt="Profile Picture">
    </div>
</template>

<script>
import defaultUserIcon from "@/assets/default_user_icon.svg";
import { appAuth } from "@/firebase/config";

export default {
    name: 'UserProfileIcon',
    data() {
        return {
            showMenu: false,
            userPictureUrl: null,
            iconTop: null,
            iconRight: null
        }
    },
    mounted() {
        appAuth.onAuthStateChanged(_user => {
            if (_user != null) {
                // Set the profile picture URL         
                if (_user.photoURL) {
                    this.userPictureUrl = _user.photoURL
                } else {
                    this.userPictureUrl = defaultUserIcon
                }
                console.log('User profile picture URL:', this.userPictureUrl)

                // Set the user name
                // const userDocRef = doc(appDB, `users/${appAuth.currentUser.uid}`);
                // getDoc(userDocRef).then((doc) => {
                //     if (doc.exists) {
                //         const userData = doc.data();
                //         this.userName = userData.name;
                //     } else {
                //         console.log("No such document!");
                //     }
                // }).catch((error) => {
                //     console.log("Error getting document:", error);
                // });
            }
        })
    },
    computed: {
        hasProfilePicture() {
            return !!this.userPictureUrl
        }
    }
}
</script>

<style scoped>
.profile-icon {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.profile-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
  