<template>
    <footer>
        <div class="footer-content">
            <p>&copy; 2024 CareeredUp</p>
            <ul>
                <li><router-link :to="{ name: 'terms' }">Terms and Conditions</router-link></li>
                <li><router-link :to="{ name: 'privacy-policy' }">Privacy Policy</router-link></li>
                <li><router-link :to="{ name: 'sub-processors' }">Sub-processors</router-link></li>
                <li><a href="mailto:support@careeredup.com">Contact Us</a></li>
            </ul>
        </div>
    </footer>
</template>

<style scoped>
footer {
    background-color: #ffffff;
    padding: 20px 0;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 20px;
    align-items: flex-start;

    p {
        flex: 1;
        text-align: left;
    }
}

.footer-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: 700px;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    flex: 3;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        text-align: left;
        gap: 6px;
        flex: none;
    }
}

.footer-content {

    a,
    p {
        color: var(--text-color-secondary);
        text-decoration: none;
        font-size: 1rem;
        line-height: 1rem;
    }
}

.footer-content ul li {
    padding: 0 10px;

    a {
        transition: color 0.3s ease;
    }

    a:hover {
        color: var(--text-color-primary);
    }
}
</style>