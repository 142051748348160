import { initializeApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCaK2Kww06wBpW1fKHrw2dRqt0hSskJcxY",
    authDomain: "careeredup.com",
    projectId: "careeredup",
    storageBucket: "careeredup.appspot.com",
    messagingSenderId: "589010471724",
    appId: "1:589010471724:web:84950309d0fd7f1641ba89"
  };


const firebaseApp = initializeApp(firebaseConfig);
const appFunctions = getFunctions(firebaseApp);
const appAuth = getAuth(firebaseApp);
const appDB = getFirestore(firebaseApp);

// If on localhost, use all firebase services locally
if (location.hostname === 'localhost') {
    console.log("Connecting to firebase functions emulator");
    connectFirestoreEmulator(appDB, 'localhost', 8080);
    connectFunctionsEmulator(appFunctions, "localhost", 5001);
    connectAuthEmulator(appAuth, "http://localhost:9099", { disableWarnings: true });
}

export { appFunctions, appAuth, appDB };